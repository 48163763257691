import { useLocation } from "react-router-dom";
import useCrud from "../../hooks/use_crud";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../components/my-components/loading_spinner";
import { order_type_classname_text, order_type_description_field_text, order_type_extrafields_field_text, order_type_name_field_text } from "../../constant";
import DataTable from 'react-data-table-component'


function OrderTypeDetails({}){

    const [loading,setLoading] = useState(false)
    const location = useLocation();
    const [orderType, setOrderType] = useState(null);
    const {findById} = useCrud({url: "ordertypes", setLoading: setLoading, firstLoad:false});

    const getOrderType = async (id) => {
        let orderTypeAux = await findById(id)
        setOrderType(orderTypeAux)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        getOrderType(searchParams.get('id'));
    }, [location]);


    const columns = [
        {name: 'Nombre',selector: (row) =>row.name,sortable: true,center:true,},
        {name: 'Tipo',selector: (row) =>row.type,sortable: true,center:true,},
    ];
    

    return (
        <div className="card">
            <div className="card-header">
                <h1>{order_type_classname_text}</h1>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <div className="details-attribute-container">
                            <h5>{order_type_name_field_text+":"}</h5>
                            <h6> {orderType && orderType.name}</h6>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h5>{order_type_description_field_text+":"}</h5>
                        <textarea style={{width:"100%"}}  disabled value={orderType?orderType.description:""}></textarea>
                    </div>
                </div>
                <br/>
                <h2 className="card-header">{order_type_extrafields_field_text}</h2>
                <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={orderType?orderType.extrafields.length:0} 
                striped={true}
                center={true}
                columns={columns}
                data={orderType?orderType.extrafields:[]}
                />
            </div>
            <LoadingSpinner isVisible={loading} />
        </div>
    );

}

export default OrderTypeDetails;