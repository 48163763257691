import React, { useEffect } from 'react';
import './loading_spinner.css';

const LoadingSpinner = ({isVisible = false }) => {


  return (
    isVisible?
    <div className='my-modal-container'>
        <div className="loading-spinner">
            <i className="fa fa-spinner fa-spin" style={{fontSize: 40+"px"}}></i>
        </div>
    </div>
    :<></>
  );
};

export default LoadingSpinner;
