import React, { useEffect } from 'react';
import { routes } from '../../routes/layouts-routes';
import { useLocation, useNavigate } from "react-router-dom";

const RouteGuard = ({}) => {
    
    const location = useLocation();
    const navigate = useNavigate();

    const procedure = async () =>{
        let rolesAux = await localStorage.getItem("userRoles")
        let roles = JSON.parse(rolesAux)
        console.log(roles)
        let actualLocation = routes.filter(route => route.path == location.pathname)[0]
        if(actualLocation && actualLocation.permissions && actualLocation.permissions!="ALL"){
            console.log("holaaa")
            console.log(actualLocation.permissions.filter(permission => roles.filter(role => role.permissions.filter(rolePermission => rolePermission == permission).length > 0).length>0).length)
            if(actualLocation.permissions.filter(permission => roles.filter(role => role.permissions.filter(rolePermission => rolePermission == permission).length > 0).length>0).length==0){
                
                localStorage.setItem("login", false);
                navigate(`${process.env.PUBLIC_URL}/login`);
            }
            
        }
/*
        if(!roles.permissions && location.pathname != "login"){
            console.log("holaaa")
            localStorage.setItem("login", false);
            navigate(`${process.env.PUBLIC_URL}/login`);
        }*/
        console.log(location.pathname)
    }

    useEffect(() => {
        procedure();
    }, [location]);

  return (
    <></>
  );
};

export default RouteGuard;