import { useEffect, useState } from "react";
import { order_assign_modal_title_text, order_assign_modal_driver_field_text, modal_cancel_text, service_classname_text,order_on_assign_button_text, service_description_field_text, service_prize_field_text, service_name_field_text, DatePickers } from "../../constant";
import AutoCompleteTextField from "../../components/my-components/autocomplete_text_field";
import { filter } from "lodash";
import useCrud from "../../hooks/use_crud";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import LoadingSpinner from "../../components/my-components/loading_spinner";


const AssignDriverModal = ({isVisible, oldRow={},onAssign, onCancel }) => {


    const [newRow, setNewRow] = useState(oldRow)
    const [selectedDriverName, setSelectedDrivername] = useState("");
    const [loading,setLoading] = useState(false)
    const {list: drivers, find} = useCrud({url: "auth", setLoading: setLoading, filters:{type: "DRIVER"}})
    const [startDate, setStartDate] = useState(oldRow.deliveryAppoinment && oldRow.deliveryAppoinment>0?new Date(oldRow.deliveryAppoinment):new Date());


    const driverNameOnChange = (name) => {

        setSelectedDrivername(name)
        find({
            type: "DRIVER",
            name:name
        },
        {
            size:10,
            page:0
        }
        )
    }

    const onSubmit = async () =>{
        let driver = drivers.filter(driverAux => driverAux.name == selectedDriverName)[0]
        console.log(drivers)
        console.log(selectedDriverName)
        console.log(drivers.filter(driverAux => driverAux.name == selectedDriverName))
        console.log(driver)
        if(driver){
            setLoading(true)
    
            let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}orders/${oldRow.id}/assign-driver?driver=${driver.id}&appoinment=${startDate.getTime()}`,
                {
                method:"PUT",
                headers:{
                    "Content-Type":"application/json",
                    "Authorization":`Bearer ${localStorage.getItem("userToken")}`
                }
                }
            )
                
            setLoading(false)
    
            if(response.ok){
                onAssign();
                return
            }

            toast.error("Upps, there are some errors. Please, try again")
        }
    
        toast.error("Seleccione un conductor")
    
        
    }

    useEffect(()=>{
        console.log(oldRow.deliveryAppoinment)
        console.log((new Date()).getTime())
        setNewRow(oldRow)
        setStartDate(oldRow.deliveryAppoinment && oldRow.deliveryAppoinment>(new Date()).getTime()?new Date(oldRow.deliveryAppoinment):new Date())
    },[oldRow])

    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                    <div className="card-header">
                        <h5>{order_assign_modal_title_text}</h5>
                    </div>
                    <div className="form theme-form">
                        <div className="card-body">
                            <AutoCompleteTextField 
                            label={order_assign_modal_driver_field_text}
                            value={selectedDriverName} 
                            options={drivers.map(driverAux => {return {...driverAux, name:driverAux.name}})}
                            onChange={driverNameOnChange}
                            />
                            <br/>
                            <DatePicker className="form-control" showIcon showTimeInput icon="fa fa-calendar" selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                        

                        <div className="card-footer text-end">
                            <button className="btn btn-primary me-2" type="button" onClick={()=>{onSubmit(); }} >{order_on_assign_button_text}</button>
                            <button className="btn btn-light" type="reset" onClick={()=>{ onCancel();}} >{modal_cancel_text}</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
            <LoadingSpinner isVisible={loading}/>
            </div>
    )
}

export default AssignDriverModal;