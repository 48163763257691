import React, { useEffect, useState, useRef } from 'react';
import './autocomplete_text_field.css';


const AutoCompleteTextField = ({className = "form-control", optionClassName = "autocomplete-text-field-option", label="", onChange= ()=>{}, value="", options = []}) => {

  const ref = useRef(null);
  const [selected, setSelected] = useState(false);

  const optionOnClick = (option) => {
    onChange(option.name)
    setSelected(false);
  }

  return (
    <div className="row">
        <div className="col">
                <label>{label}</label>
                <input ref={ref} className={className} type="text"  value={value} onClick={()=>{setSelected(true)}} onChange={(evt)=>{onChange(evt.target.value);}}/>
                <div className='dropdown-modal'>
                  <div>
                    {
                      selected &&
                      options.map( option => <div className={optionClassName} onClick={()=>{optionOnClick(option)}}>{option.name}</div>)
                    }
                  </div>
                </div>
        </div>
        <div className={selected?'my-modal-container-invisible':'hidden'} onClick={()=>{setSelected(false)}}></div>
    </div>  
    
  );
};

export default AutoCompleteTextField;