import { useEffect, useState } from "react";
import IconButton from "../../components/my-components/my_icon_button";
import { create_modal_submit_text, create_modal_text, modal_cancel_text, service_classname_text, service_description_field_text, service_prize_field_text, service_name_field_text } from "../../constant";



const CreateServiceModal = ({isVisible, onCreate, onCancel, pages = []}) => {

    const defaultModel = {
        name:"",
        description:"",
        prize:0
    }

    const [model, setModel] = useState(defaultModel)

    const reset = ()=>{
        setModel({
            name:"",
            description:"",
            prize:0
        })
    }
      

    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                <div className="card-header">
                    <h5>{create_modal_text+service_classname_text}</h5>
                </div>
                <div className="form theme-form">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{service_name_field_text}</label>
                                    <input className="form-control" id="exampleFormControlInput20" type="email" placeholder="nombredeejemplo" value={model.name} onChange={(evt)=>setModel({...model,name:evt.target.value})}/>
                                </div>
                            </div>
                        </div>   
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{service_description_field_text}</label>
                                    <textarea className="form-control" id="exampleFormControlInput20" type="email"  value={model.description} onChange={(evt)=>setModel({...model,description:evt.target.value})}/>
                                </div>
                            </div>
                        </div>   
                        <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput20">{service_prize_field_text}</label>
                                        <input className="form-control" id="exampleFormControlInput20" type="number"  placeholder="nombredeejemplo" value={model.prize} onChange={(evt)=>setModel({...model,prize:Number(evt.target.value)})}/>
                                    </div>
                                </div>
                            </div>                        
                    </div>
                    <div className="card-footer text-end">
                        <button className="btn btn-primary me-2" type="button" onClick={()=>{onCreate(model); reset();}} >{create_modal_submit_text}</button>
                        <button className="btn btn-light" type="reset" onClick={()=>{reset(); onCancel();}} >{modal_cancel_text}</button>
                    </div>
                </div>
                </div>
            </div>
            </div>
    )
}

export default CreateServiceModal;