import { useEffect, useState } from "react";
import IconButton from "../../components/my-components/my_icon_button";
import {order_type_fields_modal_double_linked_field_proportion_text, order_type_fields_modal_double_linked_field_sub_name_text,order_type_fields_modal_double_linked_field_main_name_text,dropdown_default_text,delete_text, order_type_fields_modal_options_name_column_text, order_type_fields_modal_options_value_column_text, order_type_fields_types_text, create_modal_submit_text, modal_cancel_text } from "../../constant";
import { ToastContainer, toast } from "react-toastify";



const CreateFieldModal = ({isVisible, onCreate, onCancel}) => {

    const defaultModel = {
        name:"",
        codeName:"",
        type:""
    }

    const [model, setModel] = useState(defaultModel);
    const [options, setOptions] = useState([])
    const [doubleLinkedFieldData, setDoubleLinkedFieldData] = useState({
        mainFieldName:"",
        subFieldName:"",
        proportion:""
    })


    const reset = ()=>{
        setModel({
            name:"",
            codeName:"",
            type:""
        })
        setOptions([])
    }
      
    const handleOptionsChange = (name, value, index) =>{
        let optionsAux = options.filter(option => true);
        optionsAux[index] = {
            name: name,
            value:value
        }

        setOptions(optionsAux)
    }

    const handleDeleteOption = (index) =>{
        
        let optionsAux = []
        for(let i = 0; i< options.length; i++){
            if(i!=index)
                optionsAux.push(index)
        }

        setOptions(optionsAux)
    }

    const isValidModel = () => {

        if(model.name==""){
            toast.error("El nuevo campo debe tener un nombre")
            return false
        }

        if(model.codeName==""){
            toast.error("El nuevo campo debe tener un código")
            return false
        }

        if(model.type=="options"&&!isValidOptions())
            return false

        return true
    }



    const isValidOptions = () => {
        
        if(options.length==0){
            toast.error("Los campos de tipo opciones deben contener opciones")
            return false
        }

        for(let i = 0; i< options.length; i++){
            
            if(options[i].name=="" || options.filter(option => option.name == options[i].name).length>1){
                toast.error("Opciones incompletas")
                return false
            }
        }

        return true

    }

    const getOptions = () => {
        let optionsAux = {}
        options.map(option => {optionsAux[option.name] = option.value})
        return optionsAux;
    }

    const onSubmit = ()=>{
        if(!isValidModel())
            return
        onCreate(
            model.type=="options"?
            {...model, options:getOptions()}
            :model.type =="double linked number field"?{...model, doubleLinkedFieldData: doubleLinkedFieldData}
            :model); 
        reset();
        }
   

    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                <div className="card-header">
                    <h5>Inserte la información del nuevo campo</h5>
                </div>
                <div className="form theme-form">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">Nombre</label>
                                    <input className="form-control" id="exampleFormControlInput20" type="text" placeholder="nombredeejemplo" value={model.name} onChange={(evt)=>setModel({...model,name:evt.target.value})}/>
                                </div>
                            </div>
                        </div>   
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">Código</label>
                                    <input className="form-control" id="exampleFormControlInput20" type="text" placeholder="codigo" value={model.codeName} onChange={(evt) => {
                                                                                                                                                        if (!/^[^a-zA-Z]/.test(evt.target.value)) {
                                                                                                                                                            setModel({ ...model, codeName: evt.target.value });
                                                                                                                                                        }
                                                                                                                                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect34675">Tipo</label>
                                    <select className="form-control digits" id="exampleFormControlSelect34675" value={model.type} onChange={(evt)=>setModel({...model, type:evt.target.value})}>
                                        <option value={-1}>{dropdown_default_text}</option>
                                        <option value={"text"}>{order_type_fields_types_text["text"]}</option>
                                        <option value={"number"}>{order_type_fields_types_text["number"]}</option>
                                        <option value={"positive number"}>{order_type_fields_types_text["positive number"]}</option>
                                        <option value={"nature number"}>{order_type_fields_types_text["nature number"]}</option>
                                        <option value={"options"}>{order_type_fields_types_text["options"]}</option>
                                        <option value={"double linked number field"}>{order_type_fields_types_text["double linked number field"]}</option>     
                                    </select>                                
                                </div>
                            </div>
                        </div>   
                        {
                            model.type === "options" &&
                            <div>
                                <h4>{order_type_fields_types_text["options"]}<button onClick={()=>{setOptions([...options,{name:"", value:0}])}}>+</button></h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{order_type_fields_modal_options_name_column_text}</th>
                                            <th>{order_type_fields_modal_options_value_column_text}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        options.map((option, index) =>
                                        <tr>
                                            <td>
                                                <input className="form-control" id="exampleFormControlInput20" type="text" placeholder="nombredeejemplo" value={options[index].name} onChange={(evt)=>handleOptionsChange(evt.target.value,options[index].value, index)}/>
                                            </td>
                                            <td>
                                                <input className="form-control" id="exampleFormControlInput20" type="number"  value={options[index].value} onChange={(evt)=>handleOptionsChange(options[index].name,evt.target.value, index)}/>
                                            </td>
                                            <td>
                                                <IconButton icon={
                                                    <span className='action-box large delete-btn' title='Delete Task'>
                                                        <i className='icon'>
                                                            <i className='icon-trash'></i>
                                                        </i>
                                                    </span>
                                                    }
                                                    text={delete_text}
                                                    onClick={()=>{handleDeleteOption(index)}}
                                                    color="danger"
                                                />
                                            </td>
                                        </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }  
                        {
                                        model.type == "double linked number field" &&
                                        <div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput20">{order_type_fields_modal_double_linked_field_main_name_text}</label>
                                                        <input className="form-control" id="exampleFormControlInput20" type="text"  value={doubleLinkedFieldData.mainFieldName} onChange={(evt)=>setDoubleLinkedFieldData({...doubleLinkedFieldData,mainFieldName:evt.target.value})}/>
                                                    </div>
                                                </div>
                                            </div>   
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput20">{order_type_fields_modal_double_linked_field_sub_name_text}</label>
                                                        <input className="form-control" id="exampleFormControlInput20" type="text"  value={doubleLinkedFieldData.subFieldName} onChange={(evt)=>setDoubleLinkedFieldData({...doubleLinkedFieldData,subFieldName:evt.target.value})}/>
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput20">{order_type_fields_modal_double_linked_field_proportion_text}</label>
                                                        <input className="form-control" id="exampleFormControlInput20" type="number"  value={doubleLinkedFieldData.proportion} onChange={(evt)=>setDoubleLinkedFieldData({...doubleLinkedFieldData,proportion:evt.target.value})}/>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    }             
                    </div>
                    
                    <div className="card-footer text-end">
                        <button className="btn btn-primary me-2" type="button" onClick={onSubmit} >{create_modal_submit_text}</button>
                        <button className="btn btn-light" type="reset" onClick={()=>{reset(); onCancel();}} >{modal_cancel_text}</button>
                    </div>
                </div>
                </div>
            </div>
            <ToastContainer />
            </div>
    )
}

export default CreateFieldModal;