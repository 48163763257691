import { useEffect, useState } from "react";
import { edit_modal_submit_text, edit_modal_text, modal_cancel_text, service_classname_text, service_description_field_text, service_prize_field_text, service_name_field_text } from "../../constant";



const EditOrderTypeModal = ({isVisible, oldRow={}, onEdit, onCancel, }) => {


    const [newRow, setNewRow] = useState(oldRow)


    useEffect(()=>{
        setNewRow(oldRow)
    },[oldRow])

    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                    <div className="card-header">
                        <h5>{edit_modal_text+service_classname_text}</h5>
                    </div>
                    <div className="form theme-form">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput20">{service_name_field_text}</label>
                                        <input className="form-control" id="exampleFormControlInput20"  placeholder="nombredeejemplo" value={newRow.name} onChange={(evt)=>setNewRow({...newRow,name:evt.target.value})}/>
                                    </div>
                                </div>
                            </div>   
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput20">{service_description_field_text}</label>
                                        <textarea className="form-control" id="exampleFormControlInput20" type="email"  value={newRow.description} onChange={(evt)=>setNewRow({...newRow,description:evt.target.value})}/>
                                    </div>
                                </div>
                            </div>  
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput20">{service_prize_field_text}</label>
                                        <input className="form-control" id="exampleFormControlInput20" type="number"  placeholder="nombredeejemplo" value={newRow.prize} onChange={(evt)=>setNewRow({...newRow,prize:evt.target.value})}/>
                                    </div>
                                </div>
                            </div>                      
                        </div>
                        
                        <div className="card-footer text-end">
                            <button className="btn btn-primary me-2" type="button" onClick={()=>{onEdit(newRow); }} >{edit_modal_submit_text}</button>
                            <button className="btn btn-light" type="reset" onClick={()=>{ onCancel();}} >{modal_cancel_text}</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    )
}

export default EditOrderTypeModal;