import { useEffect, useState } from "react";
import IconButton from "../../components/my-components/my_icon_button";
import { dropdown_default_text,delete_text, order_type_fields_modal_options_name_column_text, order_type_fields_modal_options_value_column_text, order_type_fields_types_text, create_modal_submit_text, modal_cancel_text, exit_text } from "../../constant";
import { ToastContainer, toast } from "react-toastify";
import { unstable_renderSubtreeIntoContainer } from "react-dom";


const TERM_TYPES = {
    VARIABLE: "variable",
    OPERATOR:"operator",
    NUMBER:"number",
    NONE:"none"
}
const resetTerm = 'C'
const operators = ['/','*','-','+']
const baseButtons = [
    '7', '8', '9', '/',
    '4', '5', '6', '*',
    '1', '2', '3', '-',
    '0', '.', resetTerm,'+' 
  ];

const PrizeExpressionKeyboard = ({isVisible, onClose, expression, setExpression, extraButtons=[]}) => {

    
    const [terms, setTerms] = useState(expression.split(" "));
    const [buttons, setButtons] = useState([...baseButtons,...extraButtons])


    
    

    useEffect(()=>{
        setTerms(expression.split(" "))
    },[expression])

    useEffect(()=>{
        setButtons([...baseButtons,...extraButtons])
    },[extraButtons])

    const handleClick = (term) => {

        let expressionAux = expression+""
        let newTermType = operators.filter(operator => operator==term).length>0?TERM_TYPES.OPERATOR
                        : extraButtons.filter(operator => operator==term).length>0?TERM_TYPES.VARIABLE
                        :TERM_TYPES.NUMBER
        let lastTermType = operators.filter(operator => operator==terms[terms.length-1]).length>0?TERM_TYPES.OPERATOR
                            : extraButtons.filter(operator => operator==terms[terms.length-1]).length>0?TERM_TYPES.VARIABLE
                            :expressionAux == "0"?TERM_TYPES.NONE:TERM_TYPES.NUMBER

        if(term == resetTerm){
            setExpression("0")
            return
        }

        if(expressionAux == "0")
            expressionAux = ""

        if(newTermType == TERM_TYPES.OPERATOR){
            if(lastTermType==TERM_TYPES.OPERATOR || lastTermType==TERM_TYPES.NONE)
                return

            setExpression(expressionAux+" "+term);
            return
        }


        if(newTermType == TERM_TYPES.VARIABLE){

            if(lastTermType!=TERM_TYPES.OPERATOR && lastTermType!=TERM_TYPES.NONE)
                return

            if(lastTermType==TERM_TYPES.NONE)
                setExpression(expressionAux+term);
            else
                setExpression(expressionAux+" "+term);

            return
        }


        if((lastTermType==TERM_TYPES.OPERATOR && isValidNumber(term)) || isValidNumber(terms[terms.length-1]+term)){
            
            if(lastTermType==TERM_TYPES.NUMBER || lastTermType==TERM_TYPES.NONE)
                setExpression(expressionAux+term);
            else
                setExpression(expressionAux+" "+term);

        }
    }

    const isValidNumber = (term) => {
            return !Number.isNaN(Number(term))  
    }

    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                <div className="form theme-form">
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <input className="form-control" id="exampleFormControlInput20" type="text" disabled placeholder="nombredeejemplo" value={expression} onChange={(evt)=>{}}/>
                            </div>
                        </div>
                    </div>  
                    <div className="keyboard-button">
                        {buttons.map((btn, index) => (
                            <button onClick={() => {handleClick(btn)}}>
                                {btn}
                            </button>
                        ))}
                    </div>
                    <div className="card-footer text-end">
                        <button className="btn btn-light" type="reset" onClick={()=>{onClose();}} >{exit_text}</button>
                    </div>
                </div>
                </div>
            </div>
            <ToastContainer />
            </div>
    )
}

export default PrizeExpressionKeyboard;