import { useState } from "react";
import {Tooltip} from 'reactstrap'




const IconButton = ({icon, text, onClick, color = "primary"}) => {


    const [basictooltip, setbasictooltip] = useState(false);
    const toggle = () => setbasictooltip(!basictooltip);
    
    return (
        <div>
            <div id={`Tooltip${text.replace(/\s+/g, '')}`} onClick={onClick}>
                <button className={`btn btn-outline-${color}`} style={{padding:"5px 10px"}} type="button">
                    {icon}
                </button>
            </div>
                <Tooltip 
                    placement="top" 
                    isOpen={basictooltip} 
                    target={`Tooltip${text.replace(/\s+/g, '')}`} 
                    toggle={toggle}>
                    {text}
                </Tooltip>
            </div>
    );
}

export default IconButton;