import { useEffect, useState } from "react";
import IconButton from "../../components/my-components/my_icon_button";
import CreateFieldModal from "./create_field_modal";
import { create_modal_submit_text, create_modal_text, delete_text, modal_cancel_text,order_type_iva_field_text, order_type_classname_text, order_type_prize_evaluation_expression_field_text, order_type_description_field_text, order_type_extrafields_field_text, order_type_name_field_text } from "../../constant";
import PrizeExpressionKeyboard from "./prize_expression_keyboard";



const CreateOrderTypeModal = ({isVisible, onCreate, onCancel, pages = []}) => {

    const defaultModel = {
        name:"",
        description:"",
        prizeEvaluationExpression:"0",
        iva:0,
        extrafields:[]
    }

    const [model, setModel] = useState(defaultModel)
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [expressionKeyboardIsOpen, setExpressionKeyboardIsOpen] = useState(false);

    const reset = ()=>{
        setModel({
            name:"",
            description:"",
            prizeEvaluationExpression:"0",
            iva:0,
            extrafields:[]
        })
    }
      

    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                <div className="card-header">
                    <h5>{create_modal_text+order_type_classname_text}</h5>
                </div>
                <div className="form theme-form">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{order_type_name_field_text}</label>
                                    <input className="form-control" id="exampleFormControlInput20" type="email" placeholder="nombredeejemplo" value={model.name} onChange={(evt)=>setModel({...model,name:evt.target.value})}/>
                                </div>
                            </div>
                        </div>   
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{order_type_description_field_text}</label>
                                    <textarea className="form-control" id="exampleFormControlInput20" type="email"  value={model.description} onChange={(evt)=>setModel({...model,description:evt.target.value})}/>
                                </div>
                            </div>
                        </div>  
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{order_type_prize_evaluation_expression_field_text}</label>
                                    <input className="form-control" id="exampleFormControlInput20" type="text" onClick={()=>{setExpressionKeyboardIsOpen(true)}} placeholder="nombredeejemplo" value={model.prizeEvaluationExpression} onChange={(evt)=>setModel({...model,prizeEvaluationExpression:evt.target.value})}/>
                                </div>
                            </div>
                        </div>   
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{order_type_iva_field_text}</label>
                                    <input className="form-control" id="exampleFormControlInput20" type="number" value={model.iva} onChange={(evt)=>setModel({...model,iva:evt.target.value})}/>
                                </div>
                            </div>
                        </div>   
                        <div >
                            <h4>{order_type_extrafields_field_text}<button onClick={()=>setCreateModalIsOpen(true)}>+</button></h4>
                            {
                               model.extrafields.map(field => 
                               <div className="field">
                                    <div className="field-text">{`${field.name}(${field.codeName})`}</div>
                                    <div className="close-icon">
                                    <IconButton icon={
                                        <span className='action-box large delete-btn' title='Delete Task'>
                                            <i className='icon'>
                                                <i className='icon-trash'></i>
                                            </i>
                                        </span>
                                        }
                                        text={delete_text}
                                        onClick={()=>{setModel({...model,extrafields:model.extrafields.filter(fieldAux=> fieldAux.name!=field.name)})}}
                                        color="danger"
                                        /> 
                                </div>
                            </div>)
                            }
                            
                        </div>                     
                    </div>
                    
                    <div className="card-footer text-end">
                        <button className="btn btn-primary me-2" type="button" onClick={()=>{onCreate(model); reset();}} >{create_modal_submit_text}</button>
                        <button className="btn btn-light" type="reset" onClick={()=>{reset(); onCancel();}} >{modal_cancel_text}</button>
                    </div>
                </div>
                <PrizeExpressionKeyboard 
                    isVisible={expressionKeyboardIsOpen} 
                    onClose={()=>setExpressionKeyboardIsOpen(false)} 
                    expression={model.prizeEvaluationExpression} 
                    setExpression={(expression)=>{setModel({...model,prizeEvaluationExpression:expression})}} 
                    extraButtons={model.extrafields
                        .filter(field => field.type!="text")
                        .map(field => field.codeName)
                    }
                    />
                <CreateFieldModal 
                isVisible= {createModalIsOpen} 
                onCreate={(field)=>{setModel({...model,extrafields:[...model.extrafields,field]});setCreateModalIsOpen(false);}} 
                onCancel= {()=>{setCreateModalIsOpen(false)}}
                />
                </div>
            </div>
            </div>
    )
}

export default CreateOrderTypeModal;