import { useEffect, useState } from "react";
import { edit_modal_submit_text, edit_modal_text, modal_cancel_text, user_classname_text, user_role_filter_text } from "../../constant";



const EditUserModal = ({isVisible, oldUser={}, onEdit, onCancel, roles = []}) => {


    const [user, setUser] = useState(oldUser)
    

    useEffect(()=>{
        setUser(oldUser)
    },[oldUser])

    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                <div className="card-header">
                    <h5>{edit_modal_text+user_classname_text}</h5>
                </div>
                <form className="form theme-form">
                    <div className="card-body">
                    <div className="row">
                        <div className="col">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect23">{user_role_filter_text}</label>
                            <select className="form-control digits" id="exampleFormControlSelect23" value={user.roles[0].id} onChange={(evt)=>{setUser({...user,roles:[{id: evt.target.value}]})}}>
                            {
                                roles.map(role => <option value={role.id}>{role.name}</option>)
                            }
                            </select>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="card-footer text-end">
                        <button className="btn btn-primary me-2" type="button" onClick={()=>{onEdit(user)}} >{edit_modal_submit_text}</button>
                        <button className="btn btn-light" type="reset" onClick={()=>{onCancel()}} >{modal_cancel_text}</button>
                    </div>
                </form>
                </div>
            </div>
            </div>
    )
}

export default EditUserModal;