import { useState } from "react";
import { user_cellphone_form_text, user_types_dropdown_label_text, create_modal_submit_text, create_modal_text, dropdown_default_text, modal_cancel_text, user_classname_text, user_email_filter_text, user_name_filter_text, user_role_filter_text, user_types_dropdown_text, user_full_name_form_text, user_family_name_form_text } from "../../constant";
import {userTypesToCreate} from "../../constant/constants.js";


const CreateUserModal = ({isVisible, onCreate, onCancel, roles = []}) => {


    const [user, setUser] = useState({
        email:"",
        username:"",
        type: "-1",
        roles:[{id:-1}],
        name:"",
        familyName:"",
        cellphone:""
    })


    const reset = ()=>{
        setUser({
            email:"",
            username:"",
            type: "-1",
            roles:[{id:-1}],
            name:"",
            familyName:"",
            cellphone:""
        })

    }

    const onSubmit = () => {
        let model = user;
        model = {
            ...model, 
            name: model.name +" "+ model.familyName    
            }

        onCreate(model)
    } 

      


    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                <div className="card-header">
                    <h5>{create_modal_text+user_classname_text}</h5>
                </div>
                <form className="form theme-form">
                    <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput20">{user_email_filter_text}</label>
                                <input className="form-control" id="exampleFormControlInput20" type="email" placeholder="name@example.com" value={user.email} onChange={(evt)=>{setUser({...user,email:evt.target.value})}}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput20">{user_name_filter_text}</label>
                                <input className="form-control" id="exampleFormControlInput20" type="email"   value={user.username} onChange={(evt)=>setUser({...user,username:evt.target.value})}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label >{user_full_name_form_text}</label>
                                    <input className="form-control"  type="text"   value={user.name} onChange={(evt)=>setUser({...user,name:evt.target.value})}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{user_family_name_form_text}</label>
                                    <input className="form-control"  type="text"   value={user.familyName} onChange={(evt)=>setUser({...user,familyName:evt.target.value})}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{user_cellphone_form_text}</label>
                                    <input className="form-control"  type="text" value={user.cellphone} onChange={(evt)=>setUser({...user,cellphone:evt.target.value})}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect23">{user_types_dropdown_label_text}</label>
                            <select className="form-control digits" id="exampleFormControlSelect23" value={user.type} onChange={(evt)=>{setUser({...user,type:evt.target.value})}}>
                            <option value={-1}>{dropdown_default_text}</option>
                            {
                               userTypesToCreate.map(type => <option value={type}>{user_types_dropdown_text[type]}</option>)
                            }
                            </select>
                        </div>
                        </div>
                    </div>
                    {
                        user.type=="ADMIN" &&
                    <div className="row">
                        <div className="col">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect23">{user_role_filter_text}</label>
                            <select className="form-control digits" id="exampleFormControlSelect23" value={user.roles[0].id} onChange={(evt)=>{setUser({...user,roles:[{id: evt.target.value}]})}}>
                            <option value={-1}>{dropdown_default_text}</option>
                            {
                                roles.map(role => <option value={role.id}>{role.name}</option>)
                            }
                            </select>
                        </div>
                        </div>
                    </div>
                    }
                    </div>
                    <div className="card-footer text-end">
                        <button className="btn btn-primary me-2" type="button" onClick={()=>{onSubmit(user); reset();}} >{create_modal_submit_text}</button>
                        <button className="btn btn-light" type="reset" onClick={()=>{reset(); onCancel();}} >{modal_cancel_text}</button>
                    </div>
                </form>
                </div>
            </div>
            </div>
    )
}

export default CreateUserModal;